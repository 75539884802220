@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #e5e5e5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-popup .leaflet-popup-content-wrapper {
  border-radius: 0px;
  width: 460px;
}

/** data grid global styles **/

.MuiDataGrid-columnSeparator svg path {
  display: none;
}

.MuiCheckbox-root svg {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid #8e8e8e;
  border-radius: 4px;
}

.MuiCheckbox-root svg path {
  display: none;
}

.MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
  border: 2px solid #2b5ddc;
  background-repeat: no-repeat;
  color: #c2d21900;
  background-size: 10px;
  background-image: url('assets/blueCheckbox.svg');
  background-position: center;
}

.MuiDataGrid-footerContainer .MuiToolbar-root .MuiButtonBase-root {
  background: #f6f4f4;
  border-radius: 8px;
  margin: 8px;
}

.menu-closed {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.menu-open {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

@keyframes rotate-icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotate-icon 0.8s linear infinite;
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.recommended-row {
  background-color: #edeef0;
}
.glass_card {
  /* From https://css.glass */

  background: rgba(244, 242, 242, 0.15);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(63, 48, 48, 0.06);
}

/*css for the credit card Info */

.ex1.container {
  margin: auto;
  padding: 100px 0;
  min-height: 100vh;
}

.ex1-wrap{
  margin: auto;
  max-width: 400px;
  border-radius: 8px;
  background: #fff;
  padding: 32px;
}

.ex1-field{
  position: relative;
  margin-bottom: 32px;
}
.ex1-fields{
  display: flex;
  margin-left: -16px;
}
.ex1-fields .ex1-field{
  flex: 1;
  margin-left: 16px;
}
.ex1-label{
  font-size: 12px;
  font-weight: 500;
  color: #7b808c;
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
}
.ex1-input{
  width: 100%;
  display: block;
  background: transparent;
  border-radius: 0;
  border: none;
  padding: 4px 2px;
  border-width: 0;
  border-color: transparent;
  color: #333;
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
  transition: .2s;
  cursor: text;
  /* font-weight: inherit; */
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}
.ex1-input::placeholder{
  color: #2b5ddc;
}
.ex1-input:focus::placeholder{
  color: #7b808c;
}

.ex1-input:focus ~ .ex1-label,
.ex1-input.focus ~ .ex1-label,
.ex1-input.val ~ .ex1-label,
.ex1-input.complete ~ .ex1-label,
.ex1-input.invalid ~ .ex1-label{
  font-size: 0.8rem;
  color:#7b808c;
  top: -1rem;
  left: 0;
}
.ex1-bar{
  position: relative;
  border-bottom: 0.0625rem solid #999;
  display: block;
}
.ex1-bar::before {
  content: '';
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #337ab7;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}
.ex1-input:focus ~ .ex1-bar::before,
.ex1-input.focus ~ .ex1-bar::before{
  width: 100%;
  left: 0;
}
.ex1-button{
  background: #e60a0a;
  background: #e60a0a;
  color: #fff;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  display: block;
  width: 100%;
  letter-spacing: .5px;
  transition: .2s;
  cursor: pointer;
}
.ex1-button:hover,
.ex1-button:focus{
  background: #c80808;
  background: #c80808;
}
.ex1-button.submit {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWxvYWRlciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNTUiIGhlaWdodD0iNTUiIHZpZXdCb3g9IjAgMCA4MCA4MCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQwIDcyQzIyLjQgNzIgOCA1Ny42IDggNDBTMjIuNCA4IDQwIDhzMzIgMTQuNCAzMiAzMmMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJjMC0xNS40LTEyLjYtMjgtMjgtMjhTMTIgMjQuNiAxMiA0MHMxMi42IDI4IDI4IDI4YzEuMSAwIDIgLjkgMiAycy0uOSAyLTIgMnoiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgNDAgNDAiIHRvPSIzNjAgNDAgNDAiIGR1cj0iMC42cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48L3BhdGg+PC9zdmc+);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  color: transparent!important;
  transition-duration: 0s;
}

.ex1-wrap .token {
  color: #555;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.ex1-wrap .error {
  color: #e41029;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}
.rotating {
  animation: rotateImage 2s linear infinite;
}
@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}